import React from "react"
import styled from "styled-components"

import { Link } from "../links/links"
import { ChevronRight } from "../marks"

import { colors, fonts, media } from "../../utils/tokens"

const PageLink = styled(Link)`
  margin: 2.5rem 0 0;
  padding: 0 0 .5rem;
  display: inline-flex;
  align-self: flex-start;
  position: relative;
  font-size: ${fonts.metaSM};
  font-weight: ${fonts.semi};
  line-height: ${fonts.metaLineHeight};
  letter-spacing: ${fonts.metaSpace};
  color: ${colors.portland};
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${colors.limestone};

    svg {
      stroke: ${colors.limestone};
    }
  }

  &::before,
  &::after {
    position: absolute;
    height: 2px;
    bottom: 0;
    content: "";
  }

  &::before {
    left: 0;
    right: 0;
    background-color: ${colors.portland};
    transition: 0.4s ease-in-out;
  }

  &:hover::before,
  &:focus::before {
    left: 100%;
    right: 0;
    transition: 0.4s ease-in-out;
    transform: scaleX;
  }

  &::after {
    left: 0;
    right: 100%;
    background-color: ${colors.limestone};
  }

  &:hover::after,
  &:focus::after {
    left: 0;
    right: 0;
    transition: 0.4s ease-in-out 0.4s;
    transform: scaleX;
  }

  @media ${media.xl} {
    margin: 4.5rem 0 0;
    padding: 0 0 .75rem;
    font-size: ${fonts.metaMD};

    &::before,
    &::after {
      height: 3px;
    }
  }
`

export const SectionLink = ({ children, to, className }) => (
  <PageLink to={to} className={className}>
    {children}
    <ChevronRight hidden="true" role="presentation" focusable="false" />
  </PageLink>
)
