import React from "react"
import styled from "styled-components"

import { Link } from "./links"

import { LtMark, WdMark } from "../marks"

import { colors, media } from "../../utils/tokens"

const Home = styled(Link)`
  padding: .75rem 1rem;
  display: inline-flex;
  align-self: ${props => (props.header ? `flex-start` : `flex-end`)};

  &:hover {
    svg {
      fill: ${colors.limestone};
    }
  }

  @media ${media.md} {
    padding: 1rem;
  }

  @media ${media.xl} {
    padding: 1rem 1.5rem;
  }
`

export const HomeLink = ({ header, to, label }) => (
  <Home header={header} to={to} aria-label={label}>
    {header ? (
      <WdMark hidden="true" role="presentation" focusable="false" />
    ) : (
      <LtMark hidden="true" role="presentation" focusable="false" />
    )}
  </Home>
)
