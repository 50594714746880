/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import GlobalStyle from "../utils/global"

import { Header } from "./header"
import { ContactSection } from "./contactSection"
import { Footer } from "./footer"

const Layout = ({ children, perspectives, noContact }) => {
  const pageLinks = [
    {
      name: `Capabilities`,
      path: `/capabilities`,
    },
    {
      name: `Studio`,
      path: `/studio`,
    },
    {
      name: `Contact`,
      path: `/contact`,
    },
  ]

  const perspectivesLinks = [
    {
      name: `Latest`,
      path: `/perspectives`,
    },
    {
      name: `Strategy`,
      path: `/perspectives/strategy`,
    },
    {
      name: `Design`,
      path: `/perspectives/design`,
    },
    {
      name: `Technology`,
      path: `/perspectives/technology`,
    },
  ]

  const legalLinks = [
    {
      name: `Terms of Use`,
      path: `/terms-of-use`,
    },
    {
      name: `Privacy Policy`,
      path: `/privacy-policy`,
    },
  ]

  const socialLinks = [
    {
      name: `LinkedIn`,
      path: `https://www.linkedin.com/company/concrezan/`,
    },
    {
      name: `Twitter`,
      path: `https://twitter.com/concrezan/`,
    },
    {
      name: `Instagram`,
      path: `https://www.instagram.com/concrezan/`,
    },
    {
      name: `Medium`,
      path: `https://medium.com/@concrezan`,
    },
  ]

  return (
    <>
      <GlobalStyle />
      <Header
        pageLinks={pageLinks}
        perspectivesLinks={perspectivesLinks}
        perspectives={perspectives}
      />
      <main>
        {children}
        <ContactSection noContact={noContact} />
        <Footer
          pageLinks={pageLinks}
          legalLinks={legalLinks}
          socialLinks={socialLinks}
        />
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
