import React, { forwardRef } from "react"
import { Link as GatsbyLink } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"

export const Link = forwardRef(
  ({ children, to, activeClassName, ...other }, ref) => {
    const internal = /^\/(?!\/)/.test(to)

    if (internal) {
      return (
        <GatsbyLink
          ref={ref}
          to={to}
          {...other}
          activeClassName={activeClassName}
        >
          {children}
        </GatsbyLink>
      )
    }

    return (
      <OutboundLink
        ref={ref}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        {...other}
      >
        {children}
      </OutboundLink>
    )
  }
)
