import styled from "styled-components"

import { media, spaces } from "../utils/tokens"

const BaseSection = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 1.5rem;

  @media ${media.lg} {
    margin: 0 2.5rem;
  }

  @media ${media.xl} {
    margin: 0 3.5rem;
  }
`

export const HeroSection = styled(BaseSection)`
  min-height: calc(100vh - 4rem);
  padding: 18rem 0 4rem;
  justify-content: flex-end;

  @media ${media.sm} {
    padding: 12rem ${spaces.sm2} 4rem 0;
  }

  @media ${media.md} {
    padding: 12rem ${spaces.sm4} 8rem 0;
  }

  @media ${media.lg} {
    padding: 12rem ${spaces.md6} 8rem 0;
  }

  @media ${media.xl} {
    padding: 12rem ${spaces.lg6} 8rem 0;
  }
`

export const SmallSection = styled(BaseSection)`
  padding: 8rem 0 6rem;

  @media ${media.sm} {
    padding: ${props =>
      props.left ? `8rem 0 6rem ${spaces.sm2}` : `8rem ${spaces.sm4} 6rem 0`};
  }

  @media ${media.lg} {
    padding: ${props =>
      props.left ? `8rem 0 6rem ${spaces.md4}` : `8rem ${spaces.md8} 6rem 0`};
  }

  @media ${media.xl} {
    padding: ${props =>
      props.left
        ? `12rem 0 6rem ${spaces.lg4}`
        : `12rem ${spaces.lg8} 8rem 0`};
  }
`

export const LargeSection = styled(BaseSection)`
  padding: 8rem 0;

  @media ${media.sm} {
    padding: ${props =>
      props.right
        ? `8rem ${spaces.sm2} 8rem 0`
        : `8rem 0 8rem ${spaces.sm2}`};
  }

  @media ${media.lg} {
    padding: ${props =>
      props.right
        ? `8rem ${spaces.md4} 8rem 0`
        : `8rem 0 8rem ${spaces.md4}`};
  }

  @media ${media.xl} {
    padding: ${props =>
      props.right
        ? `12rem ${spaces.lg4} 12rem 0`
        : `12rem 0 12rem ${spaces.lg4}`};
  }
`
