import { createGlobalStyle } from "styled-components"
import "typeface-montserrat"

import { fontDefault, fonts, colors } from "./tokens"

// Still requires checks and updates based on project progression.
// Comments will be deleted once final check is completed.

const GlobalStyle = createGlobalStyle`
  
  html {
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-family: ${fontDefault};
    font-size: ${fonts.base};
  }

  body {
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${fonts.base};
    font-weight: ${fonts.reg};
    line-height: ${fonts.pLineHeight};
    background-color: ${colors.asphalt};
    color: ${colors.portland};
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  a,
  button,
  input,
  option,
  optgroup,
  select,
  textarea {
    font-family: inherit; 
  }

  button,
  input,
  option,
  optgroup,
  select,
  textarea {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
  }

  a {
    text-decoration: none;
    background-color: transparent;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  textarea {
    overflow: auto;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }


`

export default GlobalStyle
