import React from "react"

import { SmallSection } from "./containers"
import { H2 } from "./typography"
import { FlDivider } from "./dividers"
import { SectionLink } from "./links/sectionLink"

export const ContactSection = ({ noContact }) => (
  <>
    {noContact ? null : (
      <>
        <FlDivider />
        <SmallSection>
          <H2>Want to reshape the future together?</H2>
          <SectionLink to="/contact">Let’s talk.</SectionLink>
        </SmallSection>
      </>
    )}
  </>
)
