import styled from "styled-components"

import { colors, fonts, media } from "../utils/tokens"

export const H1 = styled.h1`
  margin: 0 0 2rem;
  font-size: ${fonts.h1XS};
  font-weight: ${fonts.bold};
  line-height: ${fonts.h1LineHeight};
  letter-spacing: ${fonts.h1Space};
  color: ${colors.portland};

  &:last-child {
    margin: 0;
  }

  @media ${media.xs} {
    font-size: ${fonts.h1SM};
  }

  @media ${media.xl} {
    font-size: ${fonts.h1MD};
  }
`

export const H2 = styled.h2`
  margin: 0 0 1.5rem;
  font-size: ${fonts.h2XS};
  font-weight: ${fonts.semi};
  line-height: ${fonts.h2LineHeight};
  letter-spacing: ${fonts.h2Space};
  color: ${colors.portland};

  @media ${media.xs} {
    font-size: ${fonts.h2SM};
  }

  @media ${media.xl} {
    font-size: ${fonts.h2MD};
  }
`

export const H3 = styled.h3`
  margin: ${props => (props.withTitle ? `2rem 0 .5rem` : `0 0 .5rem`)};
  font-size: ${fonts.h3SM};
  font-weight: ${fonts.semi};
  line-height: ${fonts.h3LineHeight};
  letter-spacing: ${fonts.h3Space};
  color: ${colors.portland};

  @media ${media.xl} {
    font-size: ${fonts.h3MD};
  }
`

export const H4 = styled.h4`
  margin: 0 0 1rem;
  font-size: ${fonts.h4SM};
  font-weight: ${fonts.reg};
  line-height: ${fonts.h4LineHeight};
  letter-spacing: ${fonts.h4Space};
  color: ${colors.limestone};
  text-transform: uppercase;

  @media ${media.xl} {
    font-size: ${fonts.h4MD};
  }
`

export const P = styled.p`
  margin: 0 0 2rem;
  font-size: ${fonts.pSM};
  font-weight: ${fonts.reg};
  line-height: ${fonts.pLineHeight};
  color: ${colors.portland};

  &:nth-last-of-type(1) {
    margin: 0;
  }

  @media ${media.xl} {
    font-size: ${fonts.pMD};
  }
`

export const Meta = styled.p`
  margin: .75rem 1rem;
  padding: 0 0 4px;
  display: inline-flex;
  align-self: flex-end;
  font-size: ${fonts.metaSM};
  font-weight: ${fonts.reg};
  line-height: ${fonts.metaLineHeight};
  letter-spacing: ${fonts.metaSpace};
  color: ${colors.limestone};

  @media ${media.xl} {
    margin: 1rem 1.5rem;
    padding: 0 0 6px;
    font-size: ${fonts.metaMD};
  }
`

export const MetaPara = styled.span`
  margin: 1rem 0 0;
  padding: 0;
  display: inline-flex;
  align-self: flex-start;
  font-size: ${fonts.metaSM};
  font-weight: ${props => (props.errors ? fonts.semi : fonts.reg)};
  line-height: ${fonts.metaLineHeight};
  letter-spacing: ${fonts.metaSpace};
  color: ${props => (props.errors ? colors.redrock : colors.limestone)};

  @media ${media.xl} {
    font-size: ${fonts.metaMD};
  }
`
