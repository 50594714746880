import styled from "styled-components"

import { colors, media, spaces } from "../utils/tokens"

const Divider = styled.hr`
  max-width: 100%;
  border: none;
  border-bottom: 1px solid ${colors.limestone30};
`

export const FlDivider = styled(Divider)`
  margin: ${props => (props.nav ? `1.25rem 1rem` : `0 1.5rem`)};

  @media ${media.sm} {
    margin: ${props => (props.nav ? `1rem` : `0 2.5rem`)};
  }

  @media ${media.xl} {
    margin: ${props => (props.nav ? `1rem 1.5rem` : `0 3.5rem`)};
  }
`

export const InsDivider = styled(Divider)`
  margin: ${props =>
    props.left ? `0 1.5rem 0 ${spaces.smNM}` : `0 ${spaces.smNM} 0 1.5rem`};

  @media ${media.lg} {
    margin: ${props =>
      props.left ? `0 2.5rem 0 ${spaces.md4NM}` : `0 ${spaces.md4NM} 0 2.5rem`};
  }

  @media ${media.xl} {
    margin: ${props =>
      props.left ? `0 3.5rem 0 ${spaces.lg4NM}` : `0 ${spaces.lg4NM} 0 3.5rem`};
  }
`
