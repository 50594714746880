import React from "react"
import styled from "styled-components"

import { Link } from "./links"
import { colors, fonts, media } from "../../utils/tokens"

const Container = styled.nav`
  display: flex;
  flex-direction: ${props => (props.perspectives ? `row` : `column`)};
  overflow-x: ${props => (props.perspectives ? `auto` : `initial`)};
  margin: ${props => (props.perspectives ? `0 1.5rem 0 0` : `0`)};

  @media ${media.xs} {
    overflow-x: initial;
    margin: 0;
  }

  @media ${media.sm} {
    flex-direction: ${props => (props.legal ? `column` : `row`)};
  }
`

const NavLink = styled(Link)`
  margin: .75rem 1rem;
  padding: 0 0 4px;
  display: inline-flex;
  position: relative;
  align-self: ${props => (props.start ? `flex-start` : `flex-end`)};
  font-size: ${fonts.metaSM};
  font-weight: ${props => (props.pages ? fonts.semi : fonts.reg)};
  line-height: ${fonts.metaLineHeight};
  letter-spacing: ${fonts.metaSpace};
  color: ${props => (props.pages ? colors.portland : colors.limestone)};
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${props => (props.pages ? colors.limestone : colors.portland)};
  }

  &::before,
  &.active::before,
  &.active::after {
    position: absolute;
    height: ${props => (props.pages ? `2px` : `1px`)};
    bottom: 0;
    content: "";
  }

  &::before {
    left: 0;
    right: 100%;
    background-color: ${props =>
      props.pages ? colors.limestone : colors.portland};
    transition: 0.4s ease-in-out;
    transform: scaleX;
  }

  &:hover::before,
  &:focus::before {
    left: 0;
    right: 0;
    transition: 0.4s ease-in-out;
  }

  &.active::before {
    left: 0;
    right: 0;
    background-color: ${colors.portland};
  }

  &.active:hover::before,
  &.active:focus::before {
    left: 100%;
    right: 0;
    transition: 0.4s ease-in-out;
    transform: scaleX;
  }

  &.active::after {
    left: 0;
    right: 100%;
    background-color: ${colors.limestone};
  }

  &.active:hover::after,
  &.active:focus::after {
    left: 0;
    right: 0;
    transition: 0.4s ease-in-out 0.4s;
    transform: scaleX;
  }

  @media ${media.md} {
    margin: 1rem;
    margin: ${ props => props.footer ? `.75rem 1rem` : `1rem` };
  }

  @media ${media.xl} {
    margin: 1rem 1.5rem;
    padding: 0 0 6px;
    font-size: ${fonts.metaMD};

    &::before,
    &.active::before,
    &.active::after {
      height: ${props => (props.pages ? `3px` : `1px`)};
    }
  }
`

export const LinkGroup = ({
  links,
  className,
  activeClassName,
  footer,
  pages,
  start,
  perspectives,
  legal,
}) => (
  <Container perspectives={perspectives} legal={legal}>
    {links.map((link, id) => (
      <NavLink
        key={id}
        to={link.path}
        className={className}
        activeClassName={activeClassName}
        pages={pages}
        footer={footer}
        start={start}
      >
        {link.name}
      </NavLink>
    ))}
  </Container>
)
