import React from "react"
import styled from "styled-components"

import { HomeLink } from "./links/homeLink"
import { LinkGroup } from "./links/linkGroup"
import { FlDivider } from "./dividers"
import { Meta } from "./typography"

import { colors, media } from "../utils/tokens"

const Container = styled.footer`
  padding: 5.25rem .5rem 2.25rem;
  display: flex;
  flex-direction: column;
  background-color: ${colors.asphalt};

  @media ${media.lg} {
    padding: 5rem 1.5rem 2rem;
  }

  @media ${media.xl} {
    padding: 3rem 2rem;
  }
`

const NavSection = styled.section`
  display: flex;
  justify-content: space-between;
`

const LegalSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Copyright = () => (
  <Meta>
    © Concrezan{` `}
    {new Date().getFullYear()}
  </Meta>
)

export const Footer = ({ pageLinks, legalLinks, socialLinks }) => (
  <Container>
    <NavSection>
      <HomeLink to="/" label="Concrezan, Head to homepage" />
      <LinkGroup links={pageLinks} pages="true" />
    </NavSection>
    <FlDivider nav="true" />
    <NavSection>
      <LegalSection>
        <LinkGroup links={legalLinks} footer="true" start="true" legal="true" />
        <Copyright />
      </LegalSection>
      <LinkGroup links={socialLinks} footer="true" />
    </NavSection>
  </Container>
)
