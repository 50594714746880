import React from "react"
import styled from "styled-components"

import { HomeLink } from "./links/homeLink"
import { LinkGroup } from "./links/linkGroup"
import { FlDivider } from "./dividers"

import { colors, media } from "../utils/tokens"

const Container = styled.header`
  padding: 1.25rem .5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.asphalt};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;

  @media ${media.md} {
    padding: 2rem .5rem;
  }

  @media ${media.lg} {
    padding: 1rem 1.5rem;
  }

  @media ${media.xl} {
    padding: 1rem 2rem;
  }
`

const NavSection = styled.section`
  display: flex;
  justify-content: space-between;
`

export const Header = ({ pageLinks, perspectivesLinks, perspectives }) => (
  <Container>
    <NavSection>
      <HomeLink header="true" to="/" label="Concrezan, Head to homepage" />
      <LinkGroup links={pageLinks} activeClassName="active" pages="true" />
    </NavSection>
    {perspectives ? (
      <>
        <FlDivider nav="true" />
        <NavSection>
          <LinkGroup
            links={perspectivesLinks}
            perspectives="true"
            pages="true"
          />
        </NavSection>
      </>
    ) : null}
  </Container>
)
